import React from 'react';
import type { SectionTitleProps } from '@noths/polaris-client-ribbons-design-system';
import { SectionTitle } from '@noths/polaris-client-ribbons-design-system';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import * as styles from './SectionTitleSection.style';

export type SectionTitleSectionProps = SectionTitleProps;

export const SectionTitleSection = ({ title }: SectionTitleSectionProps) => {
  return (
    <SectionContainer columnsL={8} maxWidth="full" wrapperStyles={styles.wrapper}>
      <SectionTitle title={title} />
    </SectionContainer>
  );
};

export type { SectionTitleProps };

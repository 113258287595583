import React from 'react';
import {
  extraMaxWidthContainer,
  grid,
  GRID_NUMBER_OF_COLUMNS,
  maxWidthContainer,
  minBreakpoints,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import type { RuleSet } from 'styled-components';
import { css } from 'styled-components';

interface SectionContainerProps {
  children: React.ReactElement;
  columnsL?: number;
  columnsM?: number;
  columnsS?: number;
  columnsXL?: number;
  columnsXS?: number;
  maxWidth?: 'full' | 'max';
  wrapperStyles: RuleSet<object>;
}

export const SectionContainer = React.forwardRef(
  (
    {
      children,
      columnsL: L,
      columnsM: M,
      columnsS: S,
      columnsXL: XL,
      columnsXS: XS,
      maxWidth,
      wrapperStyles,
    }: SectionContainerProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const getColumnStart = (span: number) => (GRID_NUMBER_OF_COLUMNS - span) / 2 + 1;
    const breakpoints = { XXS: GRID_NUMBER_OF_COLUMNS, XS, S, M, L, XL };
    const mediaQueries = Object.keys(breakpoints)
      .filter((breakpoint) => breakpoints[breakpoint])
      .map(
        (breakpoint) => css`
          ${mediaQuery(
            { min: minBreakpoints[breakpoint] },
            css`
              grid-column: ${getColumnStart(breakpoints[breakpoint])} / span
                ${breakpoints[breakpoint]};
            `,
          )}
        `,
      );

    const wrappedChild = (
      <div css={[wrapperStyles, ...mediaQueries]} ref={ref}>
        {children}
      </div>
    );

    if (maxWidth) {
      return (
        <div css={[grid, maxWidth === 'full' ? maxWidthContainer : extraMaxWidthContainer]}>
          {wrappedChild}
        </div>
      );
    }

    return wrappedChild;
  },
);

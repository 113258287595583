/* istanbul ignore file */
import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  margin-bottom: ${pxToRem(spacing.md)};

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin-bottom: ${pxToRem(spacing.xl)};
    `,
  )}
`;
